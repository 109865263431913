import * as React from "react";
import {observer} from "mobx-react";
import {ErrorBoundary} from "../utilities/ErrorBoundary";
import {useEffect, useRef, useState} from "react";
import styles from "./styles/Popup.module.scss";
import classNames from "classnames";

const PopUp = observer((props: {
    isComponentVisible?: boolean,
    containerElement?: Element | null,
    className?: string,
    children: React.ReactNode}) => {
    const popUpRef = useRef<HTMLDivElement>(null);
    const [style, setStyle] = useState<{
        left?: number,
        right?: number,
        top?: number,
        bottom?: number
    } >({});

    const popUpContainerClassName = classNames({
        [styles.container]: true,
        [styles.containerVisible]: props.isComponentVisible,
        [props.className || '']: props.className,
    });

    function getElementAbsolutePosition(element) {
        let top = 0;
        let bottom = element.offsetHeight;

        // Traverse up through offset parents and accumulate offsets
        while (element) {
            top += element.offsetTop;
            element = element.offsetParent;
        }
        bottom += top; // Bottom is top position plus the element's height
        return { top, bottom };
    }

    function elementOffScreenStyles(element) {
        const styles: {
            left?: number,
            right?: number,
            top?: number,
            bottom?: number
        } = {};

        const { top, bottom } = getElementAbsolutePosition(element);

        const totalScrollHeight = document.documentElement.scrollHeight;

        const popUpRect = element.getBoundingClientRect();
        const viewportWidth = window.innerWidth;

        // Check for overflow below the bottom of the screen
        if (bottom > totalScrollHeight) {
            styles.bottom = parseInt(window.getComputedStyle(element).bottom) + (bottom - totalScrollHeight);
        }
        // Check for overflow above the top of the screen
        if (top < 0) {
            styles.top = parseInt(window.getComputedStyle(element).top) - (top);
        }
        // Check for overflow to the right
        if (popUpRect.right > viewportWidth) {
            styles.left = viewportWidth - popUpRect.right
        }
        // Check for overflow to the left
        if (popUpRect.left < 0) {
            styles.right = popUpRect.left
        }
        return styles;
    }

    useEffect(() => {
        const updatePosition = () => {
            if (popUpRef.current) {
                if (!props.isComponentVisible) {
                    if (Object.keys(style).length !== 0) setStyle({}); // Only update if style isn't already empty
                } else if (!props.containerElement) {
                    const offScreenStyle = elementOffScreenStyles(popUpRef.current);
                    if (JSON.stringify(offScreenStyle) !== JSON.stringify(style)) {
                        setStyle(offScreenStyle); // Only update if styles change
                    }
                } else {
                    const containerRect = props.containerElement.getBoundingClientRect();
                    const popupRect = popUpRef.current.getBoundingClientRect();

                    // Need to potentially iterate if we're using a reference element
                    // Using percentage / vw / vh values for padding can lead to sub-pixel
                    // rendering issues where the bounding client rect can differ from the
                    // actual size of the container slightly.
                    const containerStyles: {
                        left?: number,
                        right?: number,
                        top?: number,
                        bottom?: number
                    } = {
                        top: parseInt(popUpRef.current.style.top),
                        right: parseInt(popUpRef.current.style.right),
                        left: parseInt(popUpRef.current.style.left),
                        bottom: parseInt(popUpRef.current.style.bottom),
                    };

                    if (popupRect.right > containerRect.right) {
                        containerStyles.left = containerRect.right - popupRect.right;
                    }
                    if (popupRect.left < containerRect.left) {
                        containerStyles.left = containerRect.left;
                    }
                    if (popupRect.bottom > containerRect.bottom) {
                        containerStyles.bottom = containerRect.bottom - popupRect.bottom;
                    }
                    if (popupRect.top < containerRect.top) {
                        containerStyles.top = popupRect.top - containerRect.top;
                    }
                    if (JSON.stringify(containerStyles) !== JSON.stringify(style)) {
                        setStyle(containerStyles); // Only update if styles actually change
                    }
                }
            }
        };

        // Need to observe changes to the reference container if there is one
        if (popUpRef.current && props.containerElement) {
            const mutationObserver = new MutationObserver(updatePosition);

            mutationObserver.observe(document.body, { attributes: true, childList: true, subtree: true });

            // Initial update
            updatePosition();

            return () => {
                mutationObserver.disconnect();
            };
        }
    }, [popUpRef, props.isComponentVisible, props.containerElement]);

    return (
        <div className={popUpContainerClassName} ref={popUpRef} style={style}>
            {props.isComponentVisible && <ErrorBoundary>
                {props.children}
            </ErrorBoundary>}
        </div>
    );
})

export default PopUp;
